<template>
    <PageLayout>
        <template v-slot:default>
            <div class="d-flex justify-space-between mb-6">
                <div class="d-flex align-center">
                    <h1>
                        <template v-if="currentFilter">
                            {{ currentFilter.name }}
                        </template>
                        <template v-else>
                            {{ $t('realization.plural') }}
                        </template>
                    </h1>

                    <TableSettings
                        :fields="tableColumns"
                        :entityName="entityName"
                        @column-select="onColumnSelect"
                        className="ml-2"
                    />
                </div>
                <div class="text-right">
                    <Btn
                        color="secondary"
                        @click="filter_show = !filter_show"
                        icon
                    >
                        <v-icon>mdi-filter-plus</v-icon>
                    </Btn>
                    <Btn
                        v-if="$vuetify.breakpoint.smAndUp"
                        :to="{
                            name: 'realization.edit',
                            params: {
                                uuid: 'create'
                            }
                        }"
                        color="secondary"
                    >
                        {{ $t('realization.add') }}
                    </Btn>

                    <Teleport to="#app" v-if="!$vuetify.breakpoint.smAndUp">
                        <div class="fab__wrapper">
                            <Btn
                                :to="{
                                    name: 'realization.edit',
                                    params: {
                                        uuid: 'create'
                                    }
                                }"
                                fab
                                small
                                color="secondary"
                                :title="$t('realization.add')"
                            ><v-icon>mdi-plus</v-icon></Btn>
                        </div>
                    </Teleport>
                </div>
            </div>

            <div class="mobile-filter mb-6" v-if="$vuetify.breakpoint.smAndDown">
                <v-icon
                    class="mobile-filter__btn mr-2"
                    :disabled="userBlock"
                    color="secondary"
                    @click="filter_show === true ? filter_show = false : filter_show = true"
                    :title="$t['filter_add']"
                >mdi-filter-plus</v-icon>

                <FilterMobileLinks
                    :links="[
                        ...userFilters.map((item) => {
                            return {
                                ...item,
                                title: item.name
                            }
                        }),
                    ]"
                />
            </div>

            <FilterComponent
                class="mb-10"
                v-if="filter_show && filterData"
                :filterData="filterData"
                :currentFilter="currentFilter"
                :value="filterValues"
                :loading="loading"
                :invalid="invalid"
                entity="uved"
                @input="filterValues = $event"
                @submit="submitForm"
                @filterSaved="filterSaved"
                @filterDeleted="filterDeleted"
            />

            <template v-if="$vuetify.breakpoint.smAndDown">
                <ListItem
                    v-for="item in items"
                    :item="item"
                    :key="item.id"
                    :columns="tableColumns"
                    :userBlock="userBlock"

                    @deleteItem="deleteItem(item)"
                >
                    <template v-slot:uuid="{item}">
                        <RouterLink :to="{name: 'warehouse.realization.edit', params: {uuid: item.uuid}}">
                            {{ item.uuid }}
                        </RouterLink>
                    </template>
                </ListItem>
            </template>
            <template v-else>
                <ResizableTable
                    class="main-data-table elevation-0 uveds-table"
                    :rows="items"
                    :columns="tableColumns"
                    :sort-by="sortBy"
                    :sort-dir="sortDir"

                    @sort="sortItems"
                    @columnMoved="onColumnsMoved"
                    @columnResized="onColumnResized"
                >
                    <template v-slot:uuid="{item}">
                        <RouterLink :to="{name: 'realization.edit', params: {uuid: item.uuid}}">
                            {{ item.uuid }}
                        </RouterLink>
                    </template>
                    <template v-slot:warehouse_id="{item}">
                        <RouterLink :to="{name: 'warehouse.edit', params: {uuid: item.warehouse.uuid}}">
                            {{ item.warehouse.name }}
                        </RouterLink>
                    </template>
                    <template v-slot:organization="{item}">
                        <RouterLink :to="{name: 'organization.edit', params: {uuid: item.organization.uuid}}">
                            {{ item.organization.name }}
                        </RouterLink>
                    </template>
                </ResizableTable>
            </template>

            <div class="main-pagination">
                <SelectInput
                    id="pagination-variants"
                    :value="options.itemsPerPage"
                    :items="perPageItems"
                    :label="$t('items_per_page')"
                    :disabled="loading"
                    class="main-pagination__per-page"
                    @input="onPerPageChange($event)"
                    hide-label
                    background-color="white"
                ></SelectInput>
                <v-pagination v-model="page" :total-visible="totalVisiblePag" flat class="main-pagination__pagination pagination_table" :length="pageCount" :disabled="loading" @input="onPageChange"></v-pagination>
            </div>
        </template>
        <template v-slot:side>
            <Side />
        </template>
    </PageLayout>
</template>
<script>
import {mapGetters} from "vuex"
import {mask} from 'vue-the-mask'
import FilterComponent from "@/components/FilterComponent.vue";
import FilterView from "@/plugins/mixins/FilterView";
import ResourceView from "@/plugins/mixins/ResourceView";
import PageLayout from "@/components/Leentech/PageLayout.vue";
import SelectInput from "@/components/Form/SelectInput.vue";
import Btn from "@/components/Form/Btn.vue";
import NavMixin from "@/plugins/mixins/Nav";
import ResizableTable from "@/components/Leentech/ResizableTable.vue";
import ListItem from "@/components/ListItem.vue";
import FilterMobileLinks from "@/components/FilterMobileLinks.vue";
import Teleport from 'vue2-teleport';
import Side from "@/components/Warehouse/Side.vue";

export default {
    name: "Warehouses",
    directives: {
        mask,
    },
    components: {
        FilterMobileLinks,
        ListItem,
        ResizableTable,
        SelectInput,
        FilterComponent,
        PageLayout,
        Btn,
        Teleport,
        Side
    },
    mixins: [
        FilterView,
        ResourceView,
        NavMixin
    ],
    data() {
        return {
            entityName: 'realization',
            invalid: false,
            items: [],
            sortBy: "id",
            sortDir: false,
            page: 1,
            pageCount: 0,
            options: {
                ...FilterView.data().options,
                itemsPerPage: 10,
                sortBy: [
                    "id"
                ],
            },
            loading: false
        };
    },

    computed: {
        ...mapGetters(['userBlock', 'lang', 'itemsPerPage', 'perPageItems', 'phoneMask']),
    },

    mounted() {
        this.init();
    },

    methods: {
        init() {
            this.getItems();
            this.getFilters();
        },

        updateKey(oldVal, head) {
            if (oldVal) {
                const oldKey = oldVal;
                const newKey = head.field;
                if (oldKey === newKey) {
                    return
                }
                for (let i = 0; i < this.tableData.length; i++) {
                    Object.entries(this.tableData[i]).forEach(([key, item]) => {
                        if (key === oldKey) {
                            this.tableData[i][newKey] = this.tableData[i][key]
                            delete this.tableData[i][oldKey];
                            head.value = newKey;
                            head.show = newKey;
                        }
                    });
                }
                const foundObject = this.headerOptions.find(obj => obj.value === newKey);
                if (foundObject) {
                    foundObject.disabled = true
                }
            }
        },

        handleSuccess({results, header}) {
            this.tableData = results
            for (let i = 0; i < header.length; i++) {
                this.tableHeader.push(
                    {
                        text: header[i],
                        align: "left",
                        sortable: false,
                        value: header[i],
                        field: 'column_' + i,
                        field_clear: 'column_' + i,

                    }
                );
            }
        },

        async deleteItem(item) {
            if (confirm(this.$t('realization.delete'))) {
                var _this = this
                this.loading = true;

                await this.$http
                    .delete(`admin/warehouse/realization/${item.uuid}`)
                    .then(res => {
                        this.$toastr.success(this.$t('realization.has_been_deleted'))
                        this.getItems()
                    })
                    .catch(err => {
                        this.$toastr.success(this.$t('realization.has_not_been_deleted'))
                        if (err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },

        async restoreItem(item) {
            if (confirm(this.$t('realization.restore'))) {
                var _this = this
                this.loading = true;
                await this.$http
                    .post(`admin/warehouse/realization/${item.uuid}`)
                    .then(res => {
                        this.$toastr.success(this.$t('realization.has_been_restored'))
                        this.getItems()
                    })
                    .catch(err => {
                        this.$toastr.success(this.$t('realization.has_not_been_restored'))
                        if (err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },

        async getItems() {
            this.loading = true;
            const {
                sortBy,
                sortDesc,
                page,
                itemsPerPage
            } = this.options;

            let params = {};

            if (this.filterValues) {
                params.fltr = this.filterValues;
            }

            if (sortBy !== undefined && sortBy[0] !== undefined) {
                params.sortBy = sortBy[0];
            } else {
                params.sortBy = 'id';
            }

            if (sortDesc !== undefined && sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? 'asc' : 'desc';
            } else {
                params.sortDir = 'asc';
            }

            if (page !== undefined) {
                params.page = page
            }

            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage;
            } else {
                params.perPage = this.itemsPerPage;
            }

            await this.$http
                .get("admin/warehouse/realization", {
                    params: params,
                })
                .then(res => {
                    this.items = res.body.data
                    if (this.page > res.body.meta.last_page) {
                        this.onPageChange(res.body.meta.last_page);
                    }
                    this.totalUveds = res.body.meta.total
                    this.pageCount = res.body.meta.last_page
                })
                .catch(err => {
                    this.items = []
                    this.totalUveds = 0
                    this.$toastr.error(this.$t('failed_to_get_list_uveds'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
    }
}
</script>
